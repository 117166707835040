var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-0 mx-auto",attrs:{"max-width":"500"}},[_c('v-card-title',{staticClass:"pa-4",staticStyle:{"border-bottom":"1px solid #e8e8e8"}},[_vm._v(" 新用户注册 ")]),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"账号","rules":"required|validateUsername"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"fa fa-user-circle","label":"账号","error-messages":errors},model:{value:(_vm.ruleForm.username),callback:function ($$v) {_vm.$set(_vm.ruleForm, "username", $$v)},expression:"ruleForm.username"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"密码","rules":"required|max:20|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"密码","prepend-icon":"fa fa-lock","type":"password","error-messages":errors},model:{value:(_vm.ruleForm.password),callback:function ($$v) {_vm.$set(_vm.ruleForm, "password", $$v)},expression:"ruleForm.password"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"确认密码","rules":"required|validatePass"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"确认密码","prepend-icon":"fa fa-lock","type":"password","error-messages":errors},model:{value:(_vm.ruleForm.confirmpassword),callback:function ($$v) {_vm.$set(_vm.ruleForm, "confirmpassword", $$v)},expression:"ruleForm.confirmpassword"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"姓名","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"fa fa-user-circle","label":"姓名","error-messages":errors},model:{value:(_vm.ruleForm.realname),callback:function ($$v) {_vm.$set(_vm.ruleForm, "realname", $$v)},expression:"ruleForm.realname"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"邮箱","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"fa fa-envelope-o","label":"邮箱","error-messages":errors},model:{value:(_vm.ruleForm.email),callback:function ($$v) {_vm.$set(_vm.ruleForm, "email", $$v)},expression:"ruleForm.email"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":"验证码","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"fa fa-envelope","label":"验证码","error-messages":errors},model:{value:(_vm.ruleForm.inputCode),callback:function ($$v) {_vm.$set(_vm.ruleForm, "inputCode", $$v)},expression:"ruleForm.inputCode"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"4","align-self":"center"}},[_c('v-btn',{staticClass:"getCaptcha ml-2",attrs:{"small":"","disabled":_vm.state.smsSendBtn},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.getCaptcha.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.state.smsSendBtn && _vm.isSuccess?((_vm.state.time) + "s后重试"):'获取验证码')+" ")])],1)],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success","type":"submit","small":""}},[_vm._v("注册")]),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"error","small":""}},[_vm._v("重置")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }